<template>
	<v-row>
		<v-col>
			<v-card class="shadow-blue-not-openned cardProgress white">
				<h2 class="numberRules">{{ totalRuleOfFormation - nbreRulesView }}</h2>
				<span class="rulesStatuts">à découvrir</span>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="shadow-blue-not-openned cardProgress white">
				<h2 class="numberRules">{{ nbreRulesView - nbRulesValidated - nbRulesMemorized }}</h2>
				<span class="rulesStatuts">en cours</span>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="shadow-blue-not-openned cardProgress">
				<h2 class="numberRules">{{ nbRulesValidated }}</h2>
				<span class="rulesStatuts">maîtrisées</span>
			</v-card>
		</v-col>
		<v-col>
			<v-card class="shadow-blue-not-openned cardProgress" color="#FFD15E">
				<h2 class="numberRules">{{ nbRulesMemorized }}</h2>
				<span class="rulesStatuts">mémorisées</span>
			</v-card>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'CardsObjectivesRules',
	props: {
		totalRuleOfFormation: Number,
		nbRulesValidated: Number,
		nbRulesMemorized: Number,
		nbreRulesView: Number,
	},
};
</script>
<style lang="scss" scoped>
.cardProgress {
	padding: 10px 20px;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(45, 199, 174);
	color: white;
}

.white {
	background-color: initial !important;
	color: black;
}

.rulesStatuts {
	text-transform: uppercase;
	margin-left: 10px;
	font-weight: 700;
}
.numberRules {
	font-size: 35px;
}
@media only screen and (max-width: 600px) {
	.rulesStatuts {
		font-size: 12px;
	}
	.cardProgress {
		flex-direction: column;
		height: 6rem;
	}
	.rulesStatuts {
		text-align: center;
		margin-left: 0px;
	}
	.numberRules {
		font-size: 20px;
	}
}
</style>
