<template>
	<div class="rules-kanban-container">
		<div class="kanban-board">
			<!-- Colonne: En cours -->
			<div class="kanban-column in-progress">
				<div class="kanban-column-header">
					<h3 class="text-uppercase">En cours</h3>
					<span class="column-count">{{ inProgressRules.length }}</span>
				</div>
				<div class="kanban-column-content">
					<transition-group name="rule-card" tag="div" class="kanban-cards-container">
						<div
							v-for="rule in inProgressRules"
							:key="'progress-' + rule.id"
							class="kanban-card"
							@click="handleRuleClick(rule)"
							v-scroll-to="{
								el: '#rule-' + rule.id,
								duration: 1000,
							}"
						>
							<RulesCards :rule="rule" :ruleSelected="getRuleSelectedName" />
						</div>
					</transition-group>
					<div v-if="inProgressRules.length === 0" class="empty-column-message">
						<v-icon large color="grey lighten-1">mdi-information-outline</v-icon>
						<p>Aucune règle en cours avec les filtres actuels</p>
					</div>
				</div>
			</div>

			<!-- Colonne: Maîtrisées -->
			<div class="kanban-column mastered">
				<div class="kanban-column-header">
					<h3 class="text-uppercase">Maîtrisées</h3>
					<span class="column-count">{{ masteredRules.length }}</span>
				</div>
				<div class="kanban-column-content">
					<transition-group name="rule-card" tag="div" class="kanban-cards-container">
						<div
							v-for="rule in masteredRules"
							:key="'mastered-' + rule.id"
							class="kanban-card"
							@click="handleRuleClick(rule)"
							v-scroll-to="{
								el: '#rule-' + rule.id,
								duration: 1000,
							}"
						>
							<RulesCards :rule="rule" :ruleSelected="getRuleSelectedName" />
						</div>
					</transition-group>
					<div v-if="masteredRules.length === 0" class="empty-column-message">
						<v-icon large color="grey lighten-1">mdi-information-outline</v-icon>
						<p>Aucune règle maîtrisée avec les filtres actuels</p>
					</div>
				</div>
			</div>

			<!-- Colonne: Mémorisées -->
			<div class="kanban-column memorized">
				<div class="kanban-column-header">
					<h3 class="text-uppercase">Mémorisées</h3>
					<span class="column-count">{{ memorizedRules.length }}</span>
				</div>
				<div class="kanban-column-content">
					<transition-group name="rule-card" tag="div" class="kanban-cards-container">
						<div
							v-for="rule in memorizedRules"
							:key="'memorized-' + rule.id"
							class="kanban-card"
							@click="handleRuleClick(rule)"
							v-scroll-to="{
								el: '#rule-' + rule.id,
								duration: 1000,
							}"
						>
							<RulesCards :rule="rule" :ruleSelected="getRuleSelectedName" />
						</div>
					</transition-group>
					<div v-if="memorizedRules.length === 0" class="empty-column-message">
						<v-icon large color="grey lighten-1">mdi-information-outline</v-icon>
						<p>Aucune règle mémorisée avec les filtres actuels</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import RulesCards from './RulesCards';

export default {
	name: 'RulesKanban',
	components: {
		RulesCards,
	},
	props: {
		rules: {
			type: Array,
			required: true,
		},
		totalRuleOfFormation: {
			type: Number,
			required: true,
		},
		nbreRulesView: {
			type: Number,
			required: true,
		},
		getRuleSelectedName: {
			type: String,
			default: '',
		},
	},
	computed: {
		// Règles en cours (vues mais pas maîtrisées ni mémorisées)
		inProgressRules() {
			return this.rules.filter((rule) => (rule.viewed || rule.score > 0) && rule.score < 100);
		},
		// Règles maîtrisées (score >= 100 mais < 150)
		masteredRules() {
			return this.rules.filter((rule) => rule.score >= 100 && rule.score < 150);
		},
		// Règles mémorisées (score >= 150)
		memorizedRules() {
			return this.rules.filter((rule) => rule.score >= 150);
		},
	},
	methods: {
		handleRuleClick(rule) {
			this.$emit('rule-clicked', rule);
		},
	},
};
</script>

<style lang="scss" scoped>
.rules-kanban-container {
	width: 100%;
	overflow-x: auto;
	padding: 2rem 4rem 2rem 4rem;
}

.kanban-board {
	display: flex;
	min-height: 500px;
	gap: 1rem;
}

.kanban-column {
	flex: 1;
	min-width: 250px;
	background-color: #f8f8f8;
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	max-height: calc(100vh - 200px);

	&.to-discover {
		border-top: 4px solid #9e9e9e;
	}

	&.in-progress {
		border-top: 4px solid #ff9800;
	}

	&.mastered {
		border-top: 4px solid #34e5c8;
	}

	&.memorized {
		border-top: 4px solid #ffd15e;
	}
}

.kanban-column-header {
	padding: 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #e0e0e0;

	h3 {
		margin: 0;
		font-size: 1rem;
		font-weight: 600;
	}

	.column-count {
		background-color: rgba(0, 0, 0, 0.1);
		border-radius: 12px;
		padding: 2px 8px;
		font-size: 0.8rem;
		font-weight: 600;
	}
}

.kanban-column-content {
	padding: 1rem;
	overflow-y: auto;
	flex-grow: 1;
	position: relative;
}

.kanban-cards-container {
	min-height: 100px;
}

.kanban-card {
	margin-bottom: 1rem;
	cursor: pointer;
	transition: transform 0.2s ease;

	&:hover {
		transform: translateY(-2px);
	}
}

.empty-column-message {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	color: #9e9e9e;
	height: 150px;

	p {
		margin-top: 1rem;
		font-size: 0.9rem;
	}
}

// Animations de transition
.rule-card-enter-active,
.rule-card-leave-active {
	transition: all 0.5s;
}
.rule-card-enter,
.rule-card-leave-to {
	opacity: 0;
	transform: translateY(30px);
}
.rule-card-move {
	transition: transform 0.5s;
}

// Styles responsifs
@media screen and (max-width: 1200px) {
	.kanban-board {
		flex-wrap: wrap;
	}

	.kanban-column {
		min-width: calc(50% - 0.5rem);
		margin-bottom: 1rem;
	}
}

@media screen and (max-width: 600px) {
	.kanban-column {
		min-width: 100%;
	}
}
</style>
