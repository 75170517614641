<template>
	<div class="marginDiv">
		<Profile
			:profileData="profileInfos"
			:formationData="formationInfos"
			:exercicesDoneData="exercicesInfos"
			:listCategories="listCategories"
			:categoriesDone="categoriesDone"
		></Profile>
		<ModalFormationEndIn10Days
			:enterprise="profileInfos.entreprise_id"
			:formationData="formationInfos"
		></ModalFormationEndIn10Days>
	</div>
</template>

<script>
import Profile from './Profile';
import ModalFormationEndIn10Days from '../other/ModalFormationEndIn10Days';
import { mapGetters } from 'vuex';

export default {
	name: 'DashboardComponent',
	components: {
		Profile,
		ModalFormationEndIn10Days,
	},
	computed: {
		...mapGetters('profile', {
			profileInfos: 'profileInfos',
			formationInfos: 'formationInfos',
			exercicesInfos: 'exercicesInfos',
			categoriesDone: 'categoriesDone',
			listCategories: 'listCategories',
			formationProgress: 'formationProgress',
		}),
	},
	async mounted() {
		this.$confetti.stop();
		if (process.env.VUE_APP_MIXPANEL == 'production') {
			this.$mixpanel.track('dashboard-profile');
		}
	},
};
</script>
