<template>
	<div>
		<v-text-field
			clearable
			label="Rechercher..."
			prepend-inner-icon="mdi-magnify"
			v-model="searchText"
			@input="searchRules"
			@click:clear="clearSearch"
			color="black"
			hide-details
			class="kanban-search-field"
		></v-text-field>
	</div>
</template>

<script>
export default {
	name: 'KanbanSearch',
	data() {
		return {
			searchText: '',
		};
	},
	methods: {
		searchRules() {
			this.$emit('searchRules', this.searchText);
			this.$emit('searchTextChanged', this.searchText);
		},
		clearSearch() {
			this.searchText = '';
			this.$emit('searchRules', this.searchText);
			this.$emit('searchTextChanged', this.searchText);
		},
		resetSearch() {
			// Réinitialiser le texte de recherche
			this.searchText = '';
			// Émettre les événements pour informer le parent
			this.$emit('searchRules', this.searchText);
			this.$emit('searchTextChanged', this.searchText);
		},
	},
};
</script>

<style lang="scss" scoped>
.kanban-search-field {
	width: 500px;
}
</style>
