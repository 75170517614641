<template>
	<div class="containerRules">
		<RulesComponent v-if="orthographigFormation" :orthographigFormation="orthographigFormation" />
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import RulesComponent from '@/components/dashboard/rules/Index';

export default {
	name: 'Rules',
	components: {
		RulesComponent,
	},
	data() {
		return { orthographigFormation: null };
	},
	computed: {
		...mapGetters('profile', { allFormationsAndConfigs: 'allFormationsAndConfigs' }),
	},
	mounted() {
		if (this.allFormationsAndConfigs == null) this.$store.dispatch('profile/fetchAllFormationsAndTopic');
		else this.getOrthographigFormation();
	},
	methods: {
		getOrthographigFormation() {
			if (this.allFormationsAndConfigs == null || this.allFormationsAndConfigs.length == 0) return;
			const _orthographiqFormation = this.allFormationsAndConfigs.find((formation) => formation.for_session != true);
			this.orthographigFormation = _orthographiqFormation;
		},
	},
	watch: {
		allFormationsAndConfigs() {
			this.getOrthographigFormation();
		},
	},
};
</script>

<style lang="scss">
.containerRules {
	max-width: 80%;
	margin: auto;
}
@media screen and (max-width: 600px) {
	.containerRules {
		max-width: 90%;
		margin: auto;
	}
}
</style>
